import React from "react"
import { WatchWebinarRecording, CTAButton } from "./commonComponents"
import "./pages/resources/styles.css"
type IntegrationsMiddleSectionProps = {
  mainImage: any
  backgroundSectionColor: string
  descriptionTextTitle: JSX.Element
  descriptionTextContent: JSX.Element
  imageTextMiniature?: any
}

export const WebinarMiddleSection = ({
  mainImage,
  backgroundSectionColor,
  descriptionTextTitle,
  descriptionTextContent,
  imageTextMiniature,
  signupLink,
}: IntegrationsMiddleSectionProps) => {
  var button = signupLink ? (
    <CTAButton
      to={signupLink}
      label="SAVE YOUR SEAT"
      color="var(--text-highlight-color)"
      isExternalLink={true}
    />
  ) : (
    <WatchWebinarRecording />
  )
  return (
    <section
      id="about"
      className="about auto-detect-section auto-detect-section-less-top"
      style={{
        background: "backgroundSectionColor",
      }}
    >
      <div className="row">
        <div
          className="col-md-12 webinar-middle"
          style={{ display: "flex", width: "100%" }}
        >
          <div
            className="auto-detect-img-not-mobile"
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "#F2F2F2",
            }}
          >
            <div style={{ paddingTop: "76px", paddingBottom: "76px" }}>
              <img
                src={mainImage}
                style={{
                  width: "88%",
                  // padding: "20%",
                }}
                alt="about section"
              />
            </div>
          </div>
          <div
            className="auto-detect-info-container-50"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingTop: "5%",
              paddingRight: "5%",
              paddingBottom: "5%",
            }}
          >
            <div className="auto-detect-subcontainer-mobile">
              <div className="mobile-view-new-design-display">
                <img
                  src={mainImage}
                  alt="about section"
                  style={{ width: "88%", paddingBottom: "10%" }}
                />
              </div>
              <div
                className="section-title-new-design auto-detect-title agent-assistant-auto-detect-title"
                style={{ marginBottom: "5%", marginTop: 0, fontSize: "32px" }}
              >
                {descriptionTextTitle}
              </div>
              <div
                className="integrations-middle-section-description-content"
                style={{
                  marginBottom: "5%",
                  marginTop: 0,
                  fontSize: "16px",
                  lineHeight: "26px",
                }}
              >
                {descriptionTextContent}
              </div>
              <div style={{ marginTop: "4%" }}>
                {button}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
